import axios from 'axios';
import { toMap } from '../helpers';
import { region } from '../params';

export const fetchAllFolders = () => {
  return (dispatch, getState) => {
    const { currentProject, currentProjectLanguage } =
      getState().projectReducer;
    return axios
      .get(`/folders`, {
        params: {
          project: currentProject.id,
          project_language: currentProjectLanguage.id,
          ...(region && { region }),
        },
      })
      .then((res) => {
        let allFoldersMap = toMap(
          res.data.map((folder) => ({ ...folder, child_folders: [] })),
          'id',
        );
        res.data.forEach((folder) => {
          if (folder.parent_folder !== null) {
            if (allFoldersMap.hasOwnProperty(folder.parent_folder.id)) {
              allFoldersMap[folder.parent_folder.id].child_folders = [
                ...allFoldersMap[folder.parent_folder.id].child_folders,
                folder,
              ];
            } else {
              allFoldersMap[folder.id].child_folders.forEach((folder) => {
                delete allFoldersMap[folder.id];
              });
              delete allFoldersMap[folder.id];
            }
          }
        });
        dispatch(updateAllFoldersMap(allFoldersMap));
        const folders = Object.values(allFoldersMap);
        dispatch(updateAllFolders(folders));
        const rootFolders = folders
          .filter((folder) => {
            return folder.parent_folder === null;
          })
          .sort((a, b) => a.position - b.position);
        dispatch(updateRootFolders(rootFolders));
        return res;
      });
  };
};

export const fetchFolderArticles = (folderId) => {
  return (dispatch, getState) => {
    const { currentProject, currentProjectLanguage } =
      getState().projectReducer;
    return axios
      .get(`/folders/${folderId}/articles`, {
        params: {
          project: currentProject.id,
          project_language: currentProjectLanguage.id,
          ...(region && { region }),
        },
      })
      .then((res) => {
        dispatch(updateCurrentFolderArticles(res.data.articles));
        dispatch(updateCurrentFolderArticlesCount(res.data.count));
        return res;
      });
  };
};

export const updateAllFolders = (folders) => {
  return {
    type: 'UPDATE_ALL_FOLDERS',
    payload: folders,
  };
};

export const clearAllFolders = () => {
  return {
    type: 'CLEAR_ALL_FOLDERS',
  };
};

export const updateRootFolders = (rootFolders) => {
  return {
    type: 'UPDATE_ROOT_FOLDERS',
    payload: rootFolders,
  };
};

export const updateCurrentFolderArticles = (articles) => {
  return {
    type: 'UPDATE_CURRENT_FOLDER_ARTICLES',
    payload: articles,
  };
};

export const clearCurrentFolderArticles = () => {
  return {
    type: 'CLEAR_CURRENT_FOLDER_ARTICLES',
  };
};

export const updateCurrentFolderArticlesCount = (count) => {
  return {
    type: 'UPDATE_CURRENT_FOLDER_ARTICLES_COUNT',
    payload: count,
  };
};

export const clearCurrentFolderArticlesCount = () => {
  return {
    type: 'CLEAR_CURRENT_FOLDER_ARTICLES_COUNT',
  };
};

export const updateAllFoldersMap = (allFoldersMap) => {
  return {
    type: 'UPDATE_ALL_FOLDERS_MAP',
    payload: allFoldersMap,
  };
};

export const setFoldersLoaded = () => {
  return {
    type: 'UPDATE_IS_FOLDERS_LOADED',
    payload: true,
  };
};

export const clearFoldersLoaded = () => {
  return {
    type: 'UPDATE_IS_FOLDERS_LOADED',
    payload: false,
  };
};
