import { useAtom } from 'jotai';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { updateCurrentProjectLanguageByCode } from '../../actions/project';
import { platformAtom } from '../../atoms';
import { Platform, platformToL10nKey } from '../../constants';
import { localize } from '../../helpers';
import { isApp, isDarkMode } from '../../params';
import GlobeIcon from './GlobeIcon';
import PlatformIcon from './PlatformIcon';
import PopupCell from './PopupCell';

function NavbarPopup(props) {
  const dispatch = useDispatch();
  const [platform, setPlatform] = useAtom(platformAtom);
  const { currentProjectLanguage, currentProjectLanguages } = useSelector(
    (state) => ({
      ...state.projectReducer,
    }),
  );

  const languageOnChange = (e) => {
    dispatch(updateCurrentProjectLanguageByCode(e.target.value));
    props.setIsShown(false);
  };

  const platformOnChange = (e) => {
    setPlatform(e.target.value);
    props.setIsShown(false);
  };

  const currentLangCode = currentProjectLanguage?.language?.code || 'en';
  const isAr = currentLangCode === 'ar';

  return (
    <CSSTransition
      in={props.isShown}
      timeout={300}
      classNames='popup'
      unmountOnExit
    >
      <div className={`NavbarPopup${isDarkMode ? ' dark' : ''}`}>
        <div className='popup-mask' onClick={() => props.setIsShown(false)}>
          <div
            className='popup'
            onClick={(event) => {
              event.stopPropagation();
            }}
            style={{ top: isApp ? 20 : 50 }}
          >
            <PopupCell className='language'>
              <GlobeIcon fill={isDarkMode ? '#E1E1E1' : '#333'} />
              <select
                {...(isAr && {
                  style: { direction: 'rtl', textAlign: 'left' },
                })}
                onChange={languageOnChange}
                value={currentProjectLanguage.language.code}
              >
                {currentProjectLanguages.map((projectLanguage) => (
                  <option
                    key={projectLanguage.language.id}
                    value={projectLanguage.language.code}
                  >
                    {projectLanguage.language.name}
                  </option>
                ))}
              </select>
            </PopupCell>
            <PopupCell className='platform'>
              <PlatformIcon fill={isDarkMode ? '#E1E1E1' : '#333'} />
              <select
                {...(isAr && {
                  style: { direction: 'rtl', textAlign: 'left' },
                })}
                onChange={platformOnChange}
                value={platform}
              >
                {Object.entries(Platform).map(([k, v]) => (
                  <option key={k} value={v}>
                    {localize(currentProjectLanguage, platformToL10nKey[v])}
                  </option>
                ))}
              </select>
            </PopupCell>
            <div className='triangle' />
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}

NavbarPopup.propTypes = {
  isShown: PropTypes.bool.isRequired,
  setIsShown: PropTypes.func.isRequired,
};

export default NavbarPopup;
