import { useAtomValue } from 'jotai';
import PropTypes from 'prop-types';
import React from 'react';
import { MdChevronRight as ChevronRight } from 'react-icons/md';
import { useSelector } from 'react-redux';
import announcementIcon from '../assets/announcement_icon.svg';
import { platformAtom } from '../atoms';
import { articleTitlePrefix } from '../helpers';
import { isDarkMode } from '../params';

function Announcement(props) {
  const { currentProjectLanguage } = useSelector((state) => ({
    ...state.projectReducer,
  }));
  const platform = useAtomValue(platformAtom);

  return (
    <div
      className={`Announcement mh${isDarkMode ? ' dark' : ''}`}
      onClick={() => {
        props.navigate();
      }}
    >
      <div className='text'>
        <div className='title'>
          <img src={announcementIcon} alt='announcement-icon' />
          <div>
            {articleTitlePrefix(
              platform,
              props.article.platform,
              currentProjectLanguage,
            )}
            {props.article.title}
          </div>
        </div>
        <div className='digest'>
          <div>{props.article.digest}</div>
        </div>
      </div>
      <div className='chevron-right'>
        <ChevronRight />
      </div>
    </div>
  );
}

Announcement.propTypes = {
  article: PropTypes.object.isRequired,
  navigate: PropTypes.func,
};

Announcement.defaultProps = {
  navigate: () => {},
};

export default Announcement;
