import axios from 'axios';
import { toMap } from '../helpers';

export const fetchCurrentProject = (name) => {
  return (dispatch, getState) => {
    return axios
      .get(`/projects/${name}`)
      .then((res) => {
        if (res.status === 200) {
          dispatch(updateCurrentProject(res.data));
        }
        return res;
      })
      .catch(() => {
        dispatch(setProjectLoaded());
      });
  };
};

export const updateCurrentProject = (project) => {
  return {
    type: 'UPDATE_CURRENT_PROJECT',
    payload: project,
  };
};

export const updateCurrentProjectLanguageByCode = (projectLanguageCode) => {
  return (dispatch, getState) => {
    let currentProjectLanguage =
      getState().projectReducer.currentProjectLanguages.find(
        (projectLanguage) =>
          projectLanguage.language.code === projectLanguageCode,
      );
    currentProjectLanguage =
      currentProjectLanguage ||
      getState().projectReducer.currentProjectLanguages.find(
        (projectLanguage) =>
          projectLanguage.language.code ===
          projectLanguageCode.replace(/_.*/, ''),
      );
    currentProjectLanguage =
      currentProjectLanguage ||
      getState().projectReducer.currentProjectLanguages.find(
        (projectLanguage) => projectLanguage.language.code === 'en',
      );
    currentProjectLanguage =
      currentProjectLanguage ||
      getState().projectReducer.currentProjectLanguages[0];
    if (currentProjectLanguage) {
      localStorage.setItem(
        `${getState().projectReducer.currentProject.name}_language_mobile`,
        currentProjectLanguage.language.code,
      );
      dispatch(updateCurrentProjectLanguage(currentProjectLanguage));
    }
    return currentProjectLanguage;
  };
};

export const updateCurrentProjectLanguage = (projectLanguage) => {
  return {
    type: 'UPDATE_CURRENT_PROJECT_LANGUAGE',
    payload: projectLanguage,
  };
};

export const clearCurrentProjectLanguage = () => {
  return {
    type: 'CLEAR_CURRENT_PROJECT_LANGUAGE',
  };
};

export const updateCurrentProjectLanguages = (projectLanguages) => {
  return {
    type: 'UPDATE_CURRENT_PROJECT_LANGUAGES',
    payload: projectLanguages,
  };
};

export const updateCurrentProjectLanguagesMap = (projectLanguagesMap) => {
  return {
    type: 'UPDATE_CURRENT_PROJECT_LANGUAGES_MAP',
    payload: projectLanguagesMap,
  };
};

export const fetchCurrentProjectLanguages = () => {
  return (dispatch, getState) => {
    return axios
      .get(`/projects/${getState().projectReducer.currentProject.id}/languages`)
      .then((res) => {
        if (res.data.length > 0) {
          dispatch(updateCurrentProjectLanguages(res.data));
          const map = toMap(res.data, 'id');
          dispatch(updateCurrentProjectLanguagesMap(map));
        } else {
          dispatch(setProjectLoaded());
        }
        return res;
      })
      .catch(() => {
        dispatch(setProjectLoaded());
      });
  };
};

export const setProjectLoaded = () => {
  return {
    type: 'UPDATE_IS_PROJECT_LOADED',
    payload: true,
  };
};

export const clearProjectLoaded = () => {
  return {
    type: 'UPDATE_IS_PROJECT_LOADED',
    payload: false,
  };
};

export const setCantNav = () => {
  return {
    type: 'SET_CANT_NAV',
  };
};
