import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { isDarkMode } from '../../params';

function IOSModal(props) {
  const { currentProject } = useSelector((state) => ({
    ...state.projectReducer,
  }));

  const cancel = () => {
    props.cancelOnClick();
    props.setIsShown(false);
  };

  const confirm = () => {
    let event = new CustomEvent('seekrtech_contactus');
    window.dispatchEvent(event);
    props.confirmOnClick();
    props.setIsShown(false);
  };

  return (
    <CSSTransition
      in={props.show}
      timeout={300}
      classNames='modal'
      unmountOnExit
    >
      <div className={`IOSModal${isDarkMode ? ' dark' : ''}`}>
        <div className='mask'>
          <div className='modal'>
            <div className='text'>{props.text}</div>
            <div
              className='buttons'
              style={{ color: isDarkMode ? '#007AFF' : props.color }}
            >
              <div className='cancel-btn' onClick={cancel}>
                {props.cancelText}
              </div>
              <a
                className='confirm-btn'
                onClick={confirm}
                href={currentProject.contact_link}
              >
                {props.confirmText}
              </a>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}

IOSModal.propTypes = {
  color: PropTypes.string,
  show: PropTypes.bool.isRequired,
  setIsShown: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  cancelOnClick: PropTypes.func,
  confirmOnClick: PropTypes.func,
};

IOSModal.defaultProps = {
  color: '#349CC8',
  cancelOnClick: () => {},
  confirmOnClick: () => {},
};

export default IOSModal;
