import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import not_found from '../../assets/not_found.svg';
import not_found_dark from '../../assets/not_found_dark.svg';
import { localize } from '../../helpers';
import { isDarkMode } from '../../params';
import BackButton from './BackButton';

function NotFound(props) {
  const { currentProjectLanguage } = useSelector((state) => ({
    ...state.projectReducer,
  }));

  return (
    <div className={`NotFound${isDarkMode ? ' dark' : ''}`}>
      <img src={isDarkMode ? not_found_dark : not_found} alt='not found' />
      <div className='text'>
        {localize(currentProjectLanguage, 'article_404notfound')}
      </div>
      <BackButton history={props.history} />
    </div>
  );
}

NotFound.propTypes = {
  dark: PropTypes.bool,
  history: PropTypes.object.isRequired,
};

NotFound.defaultProps = {
  dark: false,
};

export default NotFound;
