import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import { setCantNav } from '../actions/project';
import Project from './Project';
import PageNotFound from './layout/PageNotFound';

function App(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    let urlApi = new URL(window.location.href);
    const debug = urlApi.searchParams.get('debug') === 'true';
    const cantNav = urlApi.searchParams.get('nav') === 'false';

    if (cantNav) {
      dispatch(setCantNav());
    }
  }, []);

  const location = useLocation();
  console.log(location);

  return (
    <div className='App'>
      <Switch>
        <Route path='/:project' component={Project} />
        <Route component={PageNotFound} />
      </Switch>
    </div>
  );
}

App.propTypes = {};

export default App;
