import axios from 'axios';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import VConsole from 'vconsole';
import App from './components/App';
import { setupDarkMode } from './dark-mode';
import { isDarkMode } from './params';
import './scss';
import store from './store';

if (process.env.NODE_ENV === 'development') {
  let urlApi = new URL(window.location.href);
  let vconsole = urlApi.searchParams.get('vconsole');
  if (vconsole === 'true') {
    const vConsole = new VConsole();
  }
  window.addEventListener('seekrtech_navigation', (e) => {
    console.log('seekrtech_navigation');
    console.log('seekrtech_navigation event detail:', e.detail);
  });
  window.addEventListener('seekrtech_show_menu', (e) => {
    console.log('seekrtech_show_menu');
  });
  window.addEventListener('seekrtech_contact_support', (e) => {
    console.log('seekrtech_contact_support');
  });
  window.addEventListener('seekrtech_enable_nav_bar_btns', (e) => {
    console.log('seekrtech_enable_nav_bar_btns');
  });
}

window.axios = axios;

let token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
  axios.defaults.headers.common['X-CSRF-Token'] = token.content;
}
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.baseURL = '/api';

if (isDarkMode) {
  setupDarkMode();
}

const root = createRoot(document.getElementById('app'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
);
