const initialState = {
  isFoldersLoaded: false,
  allFolders: [],
  allFoldersMap: {},
  currentFolderArticles: [],
  currentFolderArticlesCount: null,
  rootFolders: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_IS_FOLDERS_LOADED':
      return {
        ...state,
        isFoldersLoaded: action.payload,
      };
    case 'UPDATE_ALL_FOLDERS':
      return {
        ...state,
        allFolders: action.payload,
      };
    case 'CLEAR_ALL_FOLDERS':
      return {
        ...state,
        allFolders: initialState.allFolders,
      };
    case 'UPDATE_ALL_FOLDERS_MAP':
      return {
        ...state,
        allFoldersMap: action.payload,
      };
    case 'CLEAR_ALL_FOLDERS_MAP':
      return {
        ...state,
        allFoldersMap: initialState.allFoldersMap,
      };
    case 'UPDATE_CURRENT_FOLDER_ARTICLES':
      return {
        ...state,
        currentFolderArticles: action.payload,
      };
    case 'CLEAR_CURRENT_FOLDER_ARTICLES':
      return {
        ...state,
        currentFolderArticles: initialState.currentFolderArticles,
      };
    case 'UPDATE_CURRENT_FOLDER_ARTICLES_COUNT':
      return {
        ...state,
        currentFolderArticlesCount: action.payload,
      };
    case 'CLEAR_CURRENT_FOLDER_ARTICLES_COUNT':
      return {
        ...state,
        currentFolderArticlesCount: initialState.currentFolderArticlesCount,
      };
    case 'UPDATE_ROOT_FOLDERS':
      return {
        ...state,
        rootFolders: action.payload,
      };
    case 'CLEAR_ROOT_FOLDERS':
      return {
        ...state,
        rootFolders: initialState.rootFolders,
      };
    default:
      return state;
  }
};
