import lokalize from '../../lokalise.json';
import { Platform } from './constants';

export const toMap = (array, key_name) => {
  return array.reduce(function (map, obj) {
    map[obj[key_name]] = { ...obj };
    return map;
  }, {});
};

export const articleTitlePrefix = (
  platform,
  articlePlatform,
  currentProjectLanguage,
) => {
  if (platform === Platform.ALL) {
    if (articlePlatform === 'ios_all') {
      return `[ ${localize(currentProjectLanguage, 'platform_ios')} ] `;
    }
    if (articlePlatform === 'android_all') {
      return `[ ${localize(currentProjectLanguage, 'platform_android_all')} ] `;
    }
    if (articlePlatform === 'android_google') {
      return `[ ${localize(
        currentProjectLanguage,
        'platform_android_googleplay',
      )} ] `;
    }
    if (articlePlatform === 'android_others') {
      return `[ ${localize(
        currentProjectLanguage,
        'platform_android_others',
      )} ] `;
    }
  } else if (platform === Platform.IOS) {
    if (articlePlatform === 'android_all') {
      return `[ ${localize(currentProjectLanguage, 'platform_android_all')} ] `;
    }
    if (articlePlatform === 'android_google') {
      return `[ ${localize(
        currentProjectLanguage,
        'platform_android_googleplay',
      )} ] `;
    }
    if (articlePlatform === 'android_others') {
      return `[ ${localize(
        currentProjectLanguage,
        'platform_android_others',
      )} ] `;
    }
  } else if (
    platform === Platform.ANDROID_GOOGLE_PLAY ||
    platform === Platform.ANDROID_OTHERS
  ) {
    if (articlePlatform === 'ios_all') {
      return `[ ${localize(currentProjectLanguage, 'platform_ios')} ] `;
    }
  }
  return '';
};

export const has_others = (platform, articles, articlesCount) => {
  let count = 0;
  if (articlesCount === null) {
    return false;
  }
  if (platform === Platform.ALL) {
    count =
      articlesCount.general +
      articlesCount.ios_all +
      articlesCount.android_all +
      articlesCount.android_google +
      articlesCount.android_others;
  } else if (platform === Platform.IOS) {
    count = articlesCount.general + articlesCount.ios_all;
  } else if (platform === Platform.ANDROID_GOOGLE_PLAY) {
    count =
      articlesCount.general +
      articlesCount.android_all +
      articlesCount.android_google;
  } else if (platform === Platform.ANDROID_OTHERS) {
    count =
      articlesCount.general +
      articlesCount.android_all +
      articlesCount.android_others;
  }
  return articles.length < count;
};

export const localize = (currentProjectLanguage, key) => {
  return lokalize[currentProjectLanguage.language.code][key];
};
