import PropTypes from 'prop-types';
import React from 'react';

export default function ContactButton(props) {
  const pathStyle = { fill: props.primaryColor };
  return (
    <button
      style={{
        position: 'fixed',
        bottom: 50,
        right: 30,
        border: 'none',
        borderRadius: '50%',
        width: 64,
        height: 64,
        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
        backgroundColor: '#fff',
        zIndex: 1,
      }}
      onClick={props.onClick}
    >
      <svg viewBox='0 0 64 64'>
        <g>
          <path
            style={pathStyle}
            d='m48.31,34.29c1.26-1.82,1.98-3.93,1.98-6.19,0-6.87-6.69-12.44-14.94-12.44-3.63,0-6.96,1.08-9.55,2.87,1.22-.25,2.49-.38,3.8-.38,8.88,0,16.09,6.13,16.09,13.68,0,2.98-1.11,5.74-3.01,7.98,1.89,1.55,4.88,3.54,7.81,3.54-2.08-2.14-2.23-6.88-2.18-9.06Z'
          />
          <path
            style={pathStyle}
            d='m28.35,20.64c-8.25,0-14.94,5.57-14.94,12.44,0,3.37,1.61,6.43,4.23,8.67-.15,2.28-.66,5.08-2.13,6.58,2.93,0,5.92-1.99,7.81-3.54,1.57.47,3.26.72,5.03.72,8.25,0,14.94-5.56,14.94-12.44s-6.69-12.44-14.94-12.44Zm-7.25,14.36c-.97,0-1.75-.78-1.75-1.75s.78-1.75,1.75-1.75,1.75.78,1.75,1.75-.78,1.75-1.75,1.75Zm7.25,0c-.97,0-1.75-.78-1.75-1.75s.78-1.75,1.75-1.75,1.75.78,1.75,1.75-.78,1.75-1.75,1.75Zm7.25,0c-.97,0-1.75-.78-1.75-1.75s.78-1.75,1.75-1.75,1.75.78,1.75,1.75-.78,1.75-1.75,1.75Z'
          />
        </g>
        <rect style={{ fill: 'none' }} width='64' height='64' />
      </svg>
    </button>
  );
}

ContactButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  primaryColor: PropTypes.string.isRequired,
};
