import { useAtomValue } from 'jotai';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
  clearSearchedArticles,
  clearSearchedArticlesCount,
  fetchSearchedArticles,
} from '../actions/article';
import { platformAtom } from '../atoms';
import { articleTitlePrefix, has_others, localize } from '../helpers';
import Announcement from './Announcement';
import Entry from './Entry';
import ContactUs from './layout/ContactUs';
import HorizontalRule from './layout/HorizontalRule';
import IconFooter from './layout/IconFooter';
import Searchbar from './layout/Searchbar';
import Title from './layout/Title';

function ProjectIndex(props) {
  const dispatch = useDispatch();
  const platform = useAtomValue(platformAtom);
  const [searchText, setSearchText] = useState('');
  const {
    currentProject,
    currentProjectLanguage,
    rootFolders,
    announcementArticles,
    searchedArticles,
    searchedArticlesCount,
  } = useSelector((state) => ({
    ...state.projectReducer,
    ...state.folderReducer,
    ...state.articleReducer,
  }));

  const location = useLocation();
  const history = useHistory();

  const navigate = (to, folderId = null) => {
    if (to) {
      history.push(to + location.search, {
        titleId: folderId,
        canNav: true,
        isRoot: false,
      });
    }
  };

  useEffect(() => {
    //TODO verify search text is same as returned data
    if (searchText === '') {
      dispatch(clearSearchedArticles());
      dispatch(clearSearchedArticlesCount());
    } else {
      dispatch(fetchSearchedArticles(searchText));
    }
  }, [searchText]);

  useEffect(() => {
    if (!location.state) {
      history.replace(location.pathname + location.search, {
        titleId: null,
        canNav: false,
        isRoot: true,
      });
    }
    return () => {
      setSearchText('');
    };
  }, [currentProject]);

  const currentLangCode = currentProjectLanguage?.language?.code || 'en';

  return (
    <>
      <div className='ProjectIndex container'>
        <Searchbar
          lang={currentLangCode}
          color={currentProject.primary_color}
          text={searchText}
          setText={setSearchText}
          placeholder={localize(currentProjectLanguage, 'search_placeholder')}
        />
        {searchText === '' ? (
          <>
            {announcementArticles.length > 0 && (
              <Title
                title={localize(
                  currentProjectLanguage,
                  'subtitle_anncouncement',
                )}
              />
            )}
            <div className='announcements'>
              {announcementArticles.map((article) => (
                <Announcement
                  key={article.id}
                  article={article}
                  navigate={() => {
                    navigate(
                      `/${currentProject.name}/articles/${article.id}/${article.url_slug}`,
                      -2,
                    );
                  }}
                />
              ))}
            </div>
            <div style={{ height: 20 }} />

            {props.pinnedArticles.length > 0 && (
              <>
                <Title
                  title={localize(
                    currentProjectLanguage,
                    'faq_featured_articles',
                  )}
                />
                <HorizontalRule />
                {props.pinnedArticles.map(
                  (article) =>
                    article.title && (
                      <Entry
                        lang={currentLangCode}
                        key={article.id}
                        text={article.title}
                        navigate={() => {
                          navigate(
                            `/${currentProject.name}/articles/${article.id}/${article.url_slug}`,
                          );
                        }}
                      />
                    ),
                )}
                <div style={{ height: 20 }} />
              </>
            )}

            {props.hotArticles.length > 0 && (
              <>
                <Title
                  title={localize(currentProjectLanguage, 'faq_top_articles')}
                />
                <HorizontalRule />
                {props.hotArticles.map(
                  (article) =>
                    article.title && (
                      <Entry
                        lang={currentLangCode}
                        key={article.id}
                        text={article.title}
                        navigate={() => {
                          navigate(
                            `/${currentProject.name}/articles/${article.id}/${article.url_slug}`,
                          );
                        }}
                      />
                    ),
                )}
                <div style={{ height: 20 }} />
              </>
            )}

            {rootFolders.length > 0 && (
              <>
                <Title
                  title={localize(currentProjectLanguage, 'subtitle_faq')}
                />
                <HorizontalRule />
              </>
            )}
            {rootFolders.map(
              (folder) =>
                folder.name && (
                  <Entry
                    lang={currentLangCode}
                    key={folder.id}
                    text={folder.name}
                    navigate={() => {
                      navigate(
                        `/${currentProject.name}/folders/${folder.id}/${folder.url_slug}`,
                        folder.id,
                      );
                    }}
                  />
                ),
            )}
          </>
        ) : (
          <>
            {searchedArticles.map((article) => (
              <Entry
                lang={currentLangCode}
                key={article.id}
                text={`${articleTitlePrefix(
                  platform,
                  article.platform,
                  currentProjectLanguage,
                )}${article.title}`}
                navigate={() => {
                  navigate(
                    `/${currentProject.name}/articles/${article.id}/${article.url_slug}`,
                    -1,
                  );
                }}
              />
            ))}
            <div className='has-more ph'>
              {has_others(searchedArticles, searchedArticlesCount) &&
                localize(currentProjectLanguage, 'search_result_otherlanguage')}
            </div>
          </>
        )}
      </div>
      {searchText === '' ? (
        <IconFooter />
      ) : (
        <ContactUs
          hint={localize(currentProjectLanguage, 'service_hint')}
          buttonText={localize(currentProjectLanguage, 'service_btn_contactus')}
          onClick={props.contactUsOnClick}
        />
      )}
    </>
  );
}

ProjectIndex.propTypes = {
  contactUsOnClick: PropTypes.func.isRequired,
  pinnedArticles: PropTypes.array.isRequired,
  hotArticles: PropTypes.array.isRequired,
};

export default ProjectIndex;
