import { atom } from 'jotai';
import { Platform } from './constants';
import { platformParam } from './params';

let platform: (typeof Platform)[keyof typeof Platform] = Platform.ALL;
if (platformParam) {
  const key = platformParam.toUpperCase();
  if (key in Platform) {
    platform = Platform[key as keyof typeof Platform];
  }
}

export const platformAtom = atom(platform);
