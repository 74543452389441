import React from 'react';
import PropTypes from 'prop-types';

function PlatformIcon(props) {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.3047 9.07031H16.4297V6.30469C16.4297 5.76562 15.9844 5.32031 15.4453 5.32031H10.6406H9.35156H8.625C8.08594 5.32031 7.64062 5.76562 7.64062 6.30469V13.9922V17.1562V19.7109C7.64062 20.25 8.08594 20.6953 8.625 20.6953H13.5V23.4609C13.5 24 13.9453 24.4453 14.4844 24.4453H21.3047C21.8438 24.4453 22.2891 24 22.2891 23.4609V10.0547C22.2891 9.51562 21.8438 9.07031 21.3047 9.07031ZM12.0469 19.6406C11.6016 19.6406 11.25 19.2891 11.25 18.8438C11.25 18.3984 11.6016 18.0469 12.0469 18.0469C12.4922 18.0469 12.8438 18.3984 12.8438 18.8438C12.8438 19.2891 12.4688 19.6406 12.0469 19.6406ZM14.4844 9.07031C13.9453 9.07031 13.5 9.51562 13.5 10.0547V16.7812H9.60938C9.42188 16.7812 9.25781 16.6406 9.23438 16.4531C9.23438 16.4297 9.23438 16.4062 9.23438 16.4062V13.5938V7.40625C9.23438 7.19531 9.39844 7.03125 9.60938 7.03125H10.7578H14.4609C14.6719 7.03125 14.8359 7.19531 14.8359 7.40625V9.07031H14.4844ZM17.9063 23.3906C17.4609 23.3906 17.1094 23.0391 17.1094 22.5938C17.1094 22.1484 17.4609 21.7969 17.9063 21.7969C18.3516 21.7969 18.7031 22.1484 18.7031 22.5938C18.7031 23.0391 18.3281 23.3906 17.9063 23.3906ZM20.7188 20.1562C20.7188 20.3672 20.5547 20.5312 20.3438 20.5312H15.9844H15.4688C15.2813 20.5312 15.1172 20.3906 15.0938 20.2031C15.0938 20.1797 15.0938 20.1562 15.0938 20.1562V17.3438V11.1562C15.0938 10.9453 15.2578 10.7812 15.4688 10.7812H16.4297H16.6172H20.3203C20.5312 10.7812 20.6953 10.9453 20.6953 11.1562V20.1562H20.7188Z'
        fill={props.fill}
      />
    </svg>
  );
}

PlatformIcon.propTypes = {
  fill: PropTypes.string.isRequired,
};

PlatformIcon.defaultProps = {
  fill: '#333333',
};

export default PlatformIcon;
