import PropTypes from 'prop-types';
import React from 'react';
import { isDarkMode } from '../../params';

function Title(props) {
  return (
    <div className={`Title mh${isDarkMode ? ' dark' : ''}`}>{props.title}</div>
  );
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
};

Title.deafultProps = {};

export default Title;
