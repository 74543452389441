import PropTypes from 'prop-types';
import React from 'react';
import { isDarkMode } from '../../params';
import QAIcon from './QAIcon';

export default function ContactUs(props) {
  const isAr = props.lang === 'ar';

  return (
    <div className={`ContactUs${isDarkMode ? ' dark' : ''}`}>
      <div className='hint' {...(isAr && { style: { direction: 'rtl' } })}>
        {props.hint}
      </div>
      <button className='contact-btn' onClick={props.onClick}>
        <QAIcon fill={isDarkMode ? '#E1E1E1' : '#333'} />
        {props.buttonText}
      </button>
    </div>
  );
}

ContactUs.propTypes = {
  lang: PropTypes.string.isRequired,
  hint: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
