import React from 'react';
import PropTypes from 'prop-types';
import seekrtechIcon from './../../assets/seekrtech_icon.svg';

IconFooter.propTypes = {};

function IconFooter(props) {
  return (
    <div className='IconFooter'>
      <img src={seekrtechIcon} alt='seekrtech-icon' />
    </div>
  );
}

export default IconFooter;
