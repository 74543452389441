import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { localize } from '../../helpers';
import { isDarkMode } from '../../params';

function BackButton(props) {
  const { currentProjectLanguage, currentProject } = useSelector((state) => ({
    ...state.projectReducer,
  }));

  return (
    <div
      className={`BackButton${isDarkMode ? ' dark' : ''} ${
        currentProject.name
      }`}
      style={{
        background: isDarkMode ? 'transparent' : currentProject.primary_color,
      }}
      onClick={() => {
        props.history.push(
          `/${currentProject.name}` + props.history.location.search,
        );
      }}
    >
      {localize(currentProjectLanguage, 'article_back_btn')}
    </div>
  );
}

BackButton.propTypes = {
  history: PropTypes.object.isRequired,
};

export default BackButton;
