import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Remarkable } from 'remarkable';
import admonitions from 'remarkable-admonitions';
import cards from '../../../remarkable-cards/lib';
import {
  clearArticleLoaded,
  clearCurrentArticle,
  fetchCurrentArticle,
  postCurrentArticlePerformance,
  setArticleLoaded,
} from '../actions/article';
import happyIcon from '../assets/happy.svg';
import normalIcon from '../assets/normal.svg';
import sadIcon from '../assets/sad.svg';
import { localize } from '../helpers';
import { isDarkMode } from '../params';
import ArticleContainer from './ArticleContainer';
import Loading from './layout/Loading';
import NotAvailable from './layout/NotAvailable';
import NotFound from './layout/NotFound';

function Article(props) {
  const md = new Remarkable({ linkTarget: '_blank' });
  md.use(admonitions({ icon: 'svg-inline' }));
  md.use(cards());
  const dispatch = useDispatch();
  const [selectedPerformance, setSelectedPerformance] = useState(null);
  const {
    currentProject,
    currentArticle,
    isArticleLoaded,
    currentProjectLanguage,
  } = useSelector((state) => ({
    ...state.projectReducer,
    ...state.folderReducer,
    ...state.articleReducer,
  }));

  useEffect(() => {
    if (currentArticle) {
      if (!props.history.location.state) {
        props.history.replace(
          props.history.location.pathname + props.history.location.search,
          {
            titleId: -1,
            canNav: false,
            isRoot: true,
          },
        );
      }
      if (
        props.history.location.pathname !==
        `/${currentProject.name}/articles/${currentArticle.id}/${currentArticle.url_slug}`
      ) {
        props.history.replace(
          `/${currentProject.name}/articles/${currentArticle.id}/${currentArticle.url_slug}` +
            props.history.location.search,
          props.history.location.state,
        );
      }
    }
  }, [currentArticle]);

  useEffect(() => {
    dispatch(fetchCurrentArticle(props.match.params.article)).then((res) => {
      dispatch(setArticleLoaded());
    });
    return () => {
      dispatch(clearCurrentArticle());
      dispatch(clearArticleLoaded());
    };
  }, [props.match.params.article]);

  const renderedContent = () => {
    return md.render(
      currentArticle.content +
        currentArticle.slots.map((slot) => `\n\n[${slot.name}]: ${slot.url}`),
    );
  };

  const putPerformance = (performance) => {
    setSelectedPerformance(performance);
    dispatch(postCurrentArticlePerformance(performance));
  };

  const currentLangCode = currentProjectLanguage?.language?.code || 'en';
  const isAr = currentLangCode === 'ar';

  return isArticleLoaded ? (
    currentArticle ? (
      currentArticle.language_available ? (
        <>
          <div className={`Article${isDarkMode ? ' dark' : ''}`}>
            <ArticleContainer
              lang={currentLangCode}
              title={currentArticle.title}
              body={renderedContent()}
              color={currentProject.text_color}
            />
            <div className='score'>
              <div
                className='text'
                {...(isAr && { style: { direction: 'rtl' } })}
              >
                {localize(currentProjectLanguage, 'article_rate')}
              </div>
              {selectedPerformance !== null ? (
                <div className='icons'>
                  <img
                    src={happyIcon}
                    alt='happyIcon'
                    className={
                      selectedPerformance === 'happy'
                        ? 'no-cursor'
                        : 'transparent'
                    }
                  />
                  <img
                    src={normalIcon}
                    alt='normalIcon'
                    className={
                      selectedPerformance === 'normal'
                        ? 'no-cursor'
                        : 'transparent'
                    }
                  />
                  <img
                    src={sadIcon}
                    alt='sadIcon'
                    className={
                      selectedPerformance === 'sad'
                        ? 'no-cursor'
                        : 'transparent'
                    }
                  />
                </div>
              ) : (
                <div className='icons hover'>
                  <img
                    src={happyIcon}
                    alt='happyIcon'
                    onClick={() => {
                      putPerformance('happy');
                    }}
                  />
                  <img
                    src={normalIcon}
                    alt='normalIcon'
                    onClick={() => {
                      putPerformance('normal');
                    }}
                  />
                  <img
                    src={sadIcon}
                    alt='sadIcon'
                    onClick={() => {
                      putPerformance('sad');
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <NotAvailable
          languages={currentArticle.languages}
          history={props.history}
        />
      )
    ) : (
      <NotFound history={props.history} />
    )
  ) : (
    <Loading color={currentProject.primary_color} />
  );
}

Article.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

Article.defaultProps = {};

export default Article;
