import axios from 'axios';
import { region } from '../params';

export const updateAnnouncementArticles = (articles) => {
  return {
    type: 'UPDATE_ANNOUNCEMENT_ARTICLES',
    payload: articles,
  };
};
export const clearAnnouncementArticles = () => {
  return {
    type: 'CLEAR_ANNOUNCEMENT_ARTICLES',
  };
};
export const fetchAnnouncementArticles = () => {
  return (dispatch, getState) => {
    const { currentProject, currentProjectLanguage } =
      getState().projectReducer;
    return axios
      .get(`/articles`, {
        params: {
          project: currentProject.id,
          project_language: currentProjectLanguage.id,
          announcement: '',
          ...(region && { region }),
        },
      })
      .then((res) => {
        dispatch(updateAnnouncementArticles(res.data));
        return res;
      });
  };
};
export const fetchCurrentArticle = (
  articleId = null,
  projectLanguageId = null,
) => {
  return (dispatch, getState) => {
    const { currentArticle } = getState().articleReducer;
    const { currentProject, currentProjectLanguage } =
      getState().projectReducer;
    const id = articleId || currentArticle.id;
    return axios
      .get(`/articles/${id}`, {
        params: {
          project: currentProject.id,
          project_language: projectLanguageId || currentProjectLanguage.id,
          ...(region && { region }),
        },
      })
      .then((res) => {
        dispatch(updateCurrentArticle(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(setArticleLoaded());
        return err;
      });
  };
};
export const updateCurrentArticle = (article) => {
  return {
    type: 'UPDATE_CURRENT_ARTICLE',
    payload: article,
  };
};
export const clearCurrentArticle = () => {
  return {
    type: 'CLEAR_CURRENT_ARTICLE',
  };
};
export const refetchCurrentArticleSlots = () => {
  return (dispatch, getState) => {
    return axios
      .get(
        `/articles/${
          getState().articleReducer.currentArticle.id
        }/slots?project=${getState().projectReducer.currentProject.id}`,
      )
      .then((res) => {
        dispatch(updateCurrentArticleSlots(res.data));
        return res;
      });
  };
};
export const updateCurrentArticleSlots = (slots) => {
  return {
    type: 'UPDATE_CURRENT_ARTICLE_SLOTS',
    payload: slots,
  };
};
export const clearCurrentArticleSlots = () => {
  return {
    type: 'CLEAR_CURRENT_ARTICLE_SLOTS',
  };
};
export const postCurrentArticlePerformance = (performance) => {
  return (dispatch, getState) => {
    return axios
      .post(
        `/articles/${
          getState().articleReducer.currentArticle.id
        }/performance?project=${
          getState().projectReducer.currentProject.id
        }&performance=${performance}`,
      )
      .then((res) => {
        return res;
      });
  };
};
export const updateSearchedArticles = (articles) => {
  return {
    type: 'UPDATE_SEARCHED_ARTICLES',
    payload: articles,
  };
};
export const clearSearchedArticles = () => {
  return {
    type: 'CLEAR_SEARCHED_ARTICLES',
  };
};
export const updateSearchedArticlesCount = (count) => {
  return {
    type: 'UPDATE_SEARCHED_ARTICLES_COUNT',
    payload: count,
  };
};
export const clearSearchedArticlesCount = () => {
  return {
    type: 'CLEAR_SEARCHED_ARTICLES_COUNT',
  };
};
export const fetchSearchedArticles = (search) => {
  return (dispatch, getState) => {
    const { currentProject, currentProjectLanguage } =
      getState().projectReducer;
    return axios
      .get(`/articles`, {
        params: {
          project: currentProject.id,
          project_language: currentProjectLanguage.id,
          search,
          ...(region && { region }),
        },
      })
      .then((res) => {
        dispatch(updateSearchedArticles(res.data.articles));
        dispatch(updateSearchedArticlesCount(res.data.count));
        return res;
      });
  };
};
export const setArticleLoaded = () => {
  return {
    type: 'UPDATE_IS_ARTICLE_LOADED',
    payload: true,
  };
};
export const clearArticleLoaded = () => {
  return {
    type: 'UPDATE_IS_ARTICLE_LOADED',
    payload: false,
  };
};
