import { MdClear as ClearIcon, MdSearch as SearchIcon } from 'react-icons/md';

import PropTypes from 'prop-types';
import React from 'react';
import { isDarkMode } from '../../params';

function Searchbar(props) {
  const isAr = props.lang === 'ar';
  return (
    <div
      className='Searchbar'
      style={{
        backgroundColor: isDarkMode ? '#191E3F' : props.color || '#000',
      }}
    >
      <div className={`search-input${isDarkMode ? ' dark' : ''}`}>
        <input
          {...(isAr && { style: { direction: 'rtl', textAlign: 'left' } })}
          placeholder={props.placeholder}
          type='search'
          value={props.text}
          onChange={(e) => {
            props.setText(e.target.value);
          }}
        />
        {props.text === '' ? (
          <SearchIcon className='search icon' />
        ) : (
          <ClearIcon
            onClick={() => {
              props.setText('');
            }}
            className='clear icon'
          />
        )}
      </div>
    </div>
  );
}

Searchbar.propTypes = {
  lang: PropTypes.string,
  color: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  text: PropTypes.string,
  setText: PropTypes.func,
};

Searchbar.defaultProps = {
  color: '#000',
  text: '',
  setText: () => {},
};

export default Searchbar;
