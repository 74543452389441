const initialState = {
  isArticleLoaded: false,
  announcementArticles: [],
  searchedArticles: [],
  searchedArticlesCount: null,
  currentArticle: null,
  currentArticleSlots: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_IS_ARTICLE_LOADED':
      return {
        ...state,
        isArticleLoaded: action.payload,
      };
    case 'UPDATE_ANNOUNCEMENT_ARTICLES':
      return {
        ...state,
        announcementArticles: action.payload,
      };
    case 'APPEND_ANNOUNCEMENT_ARTICLES':
      return {
        ...state,
        announcementArticles: state.announcementArticles.concat(action.payload),
      };
    case 'CLEAR_ANNOUNCEMENT_ARTICLES':
      return {
        ...state,
        announcementArticles: initialState.announcementArticles,
      };
    case 'UPDATE_SEARCHED_ARTICLES':
      return {
        ...state,
        searchedArticles: action.payload,
      };
    case 'CLEAR_SEARCHED_ARTICLES':
      return {
        ...state,
        searchedArticles: initialState.searchedArticles,
      };
    case 'UPDATE_SEARCHED_ARTICLES_COUNT':
      return {
        ...state,
        searchedArticlesCount: action.payload,
      };
    case 'CLEAR_SEARCHED_ARTICLES_COUNT':
      return {
        ...state,
        searchedArticlesCount: initialState.searchedArticlesCount,
      };
    case 'UPDATE_CURRENT_ARTICLE':
      return {
        ...state,
        currentArticle: action.payload,
      };
    case 'CLEAR_CURRENT_ARTICLE':
      return {
        ...state,
        currentArticle: initialState.currentArticle,
      };
    case 'UPDATE_CURRENT_ARTICLE_SLOTS':
      return {
        ...state,
        currentArticleSlots: action.payload,
      };
    case 'CLEAR_CURRENT_ARTICLE_SLOTS':
      return {
        ...state,
        currentArticleSlots: initialState.currentArticleSlots,
      };
    default:
      return state;
  }
};
