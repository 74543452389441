import PropTypes from 'prop-types';
import React from 'react';
import { MdChevronRight as ChevronRight } from 'react-icons/md';
import { isDarkMode } from '../params';
import HorizontalRule from './layout/HorizontalRule';

function Entry(props) {
  const isAr = props.lang === 'ar';
  return (
    <>
      <div
        className={`Entry pl${isDarkMode ? ' dark' : ''}`}
        onClick={() => {
          props.navigate();
        }}
      >
        <div
          className='text'
          {...(isAr && {
            style: { direction: 'rtl', justifyContent: 'flex-end' },
          })}
        >
          {props.text}
        </div>
        <div className='chevron-right'>
          <ChevronRight />
        </div>
      </div>
      <HorizontalRule
        className='ml'
        borderBottom='1px solid rgba(165, 165, 165, 0.6)'
      />
    </>
  );
}

Entry.propTypes = {
  text: PropTypes.string,
  isFolder: PropTypes.bool,
  navigate: PropTypes.func,
  lang: PropTypes.string,
};

Entry.defaultProps = {
  text: '',
  isFolder: false,
  navigate: () => {},
};

export default Entry;
