import React from 'react';
import PropTypes from 'prop-types';

function QAIcon(props) {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M22.6406 16.0781C23.2266 15.2343 23.5781 14.2265 23.5781 13.1718C23.5781 9.96091 20.4375 7.33591 16.5703 7.33591C14.8594 7.33591 13.3125 7.85153 12.0938 8.67184C12.6562 8.55466 13.2656 8.48435 13.875 8.48435C18.0469 8.48435 21.4219 11.3672 21.4219 14.9062C21.4219 16.3125 20.9063 17.6015 20.0156 18.6562C20.9063 19.3828 22.3125 20.3203 23.6719 20.3203C22.6875 19.3125 22.6172 17.1093 22.6406 16.0781Z'
        fill={props.fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.28125 15.5156C6.28125 12.2812 9.42187 9.67966 13.2891 9.67966C17.1562 9.67966 20.2969 12.2812 20.2969 15.4922C20.2969 18.7265 17.1562 21.3281 13.2891 21.3281C12.4453 21.3281 11.6484 21.2109 10.9219 21C10.0312 21.7265 8.64844 22.664 7.26562 22.664C7.96875 21.9609 8.20312 20.6484 8.27344 19.5703C7.03125 18.539 6.28125 17.0859 6.28125 15.5156ZM10.7109 15.5859C10.7109 16.039 10.3437 16.4062 9.89062 16.4062C9.43758 16.4062 9.07031 16.039 9.07031 15.5859C9.07031 15.1329 9.43758 14.7656 9.89062 14.7656C10.3437 14.7656 10.7109 15.1329 10.7109 15.5859ZM13.2891 16.4062C13.7421 16.4062 14.1094 16.039 14.1094 15.5859C14.1094 15.1329 13.7421 14.7656 13.2891 14.7656C12.836 14.7656 12.4688 15.1329 12.4688 15.5859C12.4688 16.039 12.836 16.4062 13.2891 16.4062ZM17.5078 15.5859C17.5078 16.039 17.1405 16.4062 16.6875 16.4062C16.2345 16.4062 15.8672 16.039 15.8672 15.5859C15.8672 15.1329 16.2345 14.7656 16.6875 14.7656C17.1405 14.7656 17.5078 15.1329 17.5078 15.5859Z'
        fill={props.fill}
      />
    </svg>
  );
}

QAIcon.propTypes = {
  fill: PropTypes.string.isRequired,
};

QAIcon.defaultProps = {
  fill: '#333333',
};

export default QAIcon;
