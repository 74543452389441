import { useAtomValue } from 'jotai';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearCurrentFolderArticles,
  clearCurrentFolderArticlesCount,
  clearFoldersLoaded,
  fetchFolderArticles,
  setFoldersLoaded,
} from '../actions/folder';
import { platformAtom } from '../atoms';
import { Platform } from '../constants';
import { articleTitlePrefix, has_others, localize } from '../helpers';
import Entry from './Entry';
import IconFooter from './layout/IconFooter';
import Loading from './layout/Loading';
import NotAvailable from './layout/NotAvailable';
import NotFound from './layout/NotFound';

function FolderIndex(props) {
  const dispatch = useDispatch();
  const platform = useAtomValue(platformAtom);
  const [currentFolder, setCurrentFolder] = useState(null);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const {
    currentProject,
    currentProjectLanguage,
    allFoldersMap,
    currentFolderArticles,
    isFoldersLoaded,
    currentFolderArticlesCount,
  } = useSelector((state) => ({
    ...state.folderReducer,
    ...state.projectReducer,
  }));

  const navigate = (to, folderId = null) => {
    if (to) {
      props.history.push(to + props.history.location.search, {
        titleId: folderId,
        canNav: true,
        isRoot: false,
      });
    }
  };

  useEffect(() => {
    setFilteredArticles(
      currentFolderArticles.filter((article) => {
        if (platform === Platform.ALL) {
          return true;
        } else if (platform === Platform.IOS) {
          if (
            article.platform === 'general' ||
            article.platform === 'ios_all'
          ) {
            return true;
          }
        } else if (platform === Platform.ANDROID_GOOGLE_PLAY) {
          if (
            article.platform === 'general' ||
            article.platform === 'android_all' ||
            article.platform === 'android_google'
          ) {
            return true;
          }
        } else if (platform === Platform.ANDROID_OTHERS) {
          if (
            article.platform === 'general' ||
            article.platform === 'android_all' ||
            article.platform === 'android_others'
          ) {
            return true;
          }
        }
        return false;
      }),
    );
  }, [currentFolderArticles, platform]);

  useEffect(() => {
    if (currentFolder) {
      dispatch(fetchFolderArticles(currentFolder.id))
        .then(() => {
          dispatch(setFoldersLoaded());
        })
        .catch(() => {
          dispatch(setFoldersLoaded());
        });
      if (!props.history.location.state) {
        props.history.replace(
          props.history.location.pathname + props.history.location.search,
          {
            titleId: currentFolder.id,
            canNav: false,
            isRoot: true,
          },
        );
      }
      if (
        props.history.location.pathname !==
        `/${currentProject.name}/folders/${currentFolder.id}/${currentFolder.url_slug}`
      ) {
        props.history.replace(
          `/${currentProject.name}/folders/${currentFolder.id}/${currentFolder.url_slug}` +
            props.history.location.search,
          props.history.location.state,
        );
      }
    }
    return () => {
      dispatch(clearCurrentFolderArticles());
      dispatch(clearCurrentFolderArticlesCount());
    };
  }, [currentFolder]);

  useEffect(() => {
    if (Object.getOwnPropertyNames(allFoldersMap).length > 0) {
      const currentFolder = allFoldersMap[parseInt(props.match.params.folder)];
      if (currentFolder) {
        setCurrentFolder(currentFolder);
      } else {
        dispatch(setFoldersLoaded());
      }
    }
    return () => {
      setCurrentFolder(null);
      dispatch(clearFoldersLoaded());
    };
  }, [props.match.params.folder, allFoldersMap]);

  const currentLangCode = currentProjectLanguage?.language?.code || 'en';

  return isFoldersLoaded ? (
    currentFolder ? (
      currentFolder.name ? (
        <>
          <div className='container'>
            {currentFolder.child_folders.map(
              (folder) =>
                folder.name && (
                  <Entry
                    lang={currentLangCode}
                    key={folder.id}
                    text={folder.name}
                    navigate={() => {
                      navigate(
                        `/${currentProject.name}/folders/${folder.id}/${folder.url_slug}`,
                        folder.id,
                      );
                    }}
                  />
                ),
            )}
            {filteredArticles.map((article) => (
              <Entry
                lang={currentLangCode}
                key={article.id}
                text={`${articleTitlePrefix(
                  platform,
                  article.platform,
                  currentProjectLanguage,
                )}${article.title}`}
                navigate={() => {
                  navigate(
                    `/${currentProject.name}/articles/${article.id}/${article.url_slug}`,
                    currentFolder.id,
                  );
                }}
              />
            ))}
            <div className='has-more ph'>
              {has_others(filteredArticles, currentFolderArticlesCount) &&
                localize(currentProjectLanguage, 'folder_result_otherlanguage')}
            </div>
          </div>
          <IconFooter />
        </>
      ) : (
        <NotAvailable history={props.history} />
      )
    ) : (
      <NotFound history={props.history} />
    )
  ) : (
    <Loading color={currentProject.primary_color} />
  );
}

FolderIndex.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

FolderIndex.defaultProps = {};

export default FolderIndex;
