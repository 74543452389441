import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import {
  clearAnnouncementArticles,
  fetchAnnouncementArticles,
} from '../actions/article';
import { clearAllFolders, fetchAllFolders } from '../actions/folder';
import {
  clearProjectLoaded,
  fetchCurrentProject,
  fetchCurrentProjectLanguages,
  setProjectLoaded,
  updateCurrentProjectLanguageByCode,
} from '../actions/project';
import { localize } from '../helpers';
import { isApp, region } from '../params';
import Article from './Article';
import FolderIndex from './FolderIndex';
import ProjectIndex from './ProjectIndex';
import IOSModal from './layout/IOSModal';
import Loading from './layout/Loading';
import Navbar from './layout/Navbar';
import NavbarPopup from './layout/NavbarPopup';
import PageNotFound from './layout/PageNotFound';
import ContactButton from './layout/contact-button';

function Project(props) {
  const dispatch = useDispatch();
  const [isNavbarPopupShown, setIsNavbarPopupShown] = useState(false);
  const [isContactUsModalShown, setIsContactUsModalShown] = useState(false);
  const {
    currentProject,
    currentProjectLanguage,
    currentProjectLanguages,
    projects,
    canNav,
    allFoldersMap,
    isProjectLoaded,
  } = useSelector((state) => ({
    ...state.projectReducer,
    ...state.folderReducer,
  }));

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    console.log('location.state:', location.state);
  }, [location.state]);

  useEffect(() => {
    if (
      location.state &&
      currentProjectLanguage &&
      Object.keys(allFoldersMap).length > 0
    ) {
      let event = new CustomEvent('seekrtech_navigation', {
        detail: {
          isRoot: location.state.isRoot,
          title: navbarTitle(),
        },
      });
      window.dispatchEvent(event);
    }
  }, [allFoldersMap, location.state, currentProjectLanguage]);

  useEffect(() => {
    dispatch(fetchCurrentProject(props.match.params.project));
    return () => {
      dispatch(clearProjectLoaded());
    };
  }, [projects, props.match.params.project]);

  useEffect(() => {
    if (currentProject) {
      dispatch(fetchCurrentProjectLanguages());
    }
  }, [currentProject]);

  useEffect(() => {
    if (currentProjectLanguages.length > 0) {
      let urlApi = new URL(window.location.href);
      let languageCode =
        urlApi.searchParams.get('language') ||
        localStorage.getItem(`${currentProject.name}_language_mobile`) ||
        window.navigator.userLanguage ||
        window.navigator.language;
      languageCode = languageCode.replace(
        /[-_](.*)/,
        (match, p1) => '_' + p1.toUpperCase(),
      );
      dispatch(updateCurrentProjectLanguageByCode(languageCode));
    }
  }, [currentProjectLanguages]);

  useEffect(() => {
    if (currentProjectLanguage) {
      Promise.all([
        dispatch(fetchAllFolders()),
        dispatch(fetchAnnouncementArticles()),
      ])
        .then(() => {
          dispatch(setProjectLoaded());
        })
        .catch(() => {
          dispatch(setProjectLoaded());
        });
    }
    return () => {
      dispatch(clearAllFolders());
      dispatch(clearAnnouncementArticles());
      dispatch(clearProjectLoaded());
    };
  }, [currentProjectLanguage]);

  useEffect(() => {
    if (isNavbarPopupShown === false) {
      let event = new CustomEvent('seekrtech_enable_nav_bar_btns');
      window.dispatchEvent(event);
    }
  }, [isNavbarPopupShown]);

  useEffect(() => {
    window.addEventListener('seekrtech_show_menu', (e) => {
      setIsNavbarPopupShown(true);
    });
    const sendFinishedLoadingEvent = () => {
      const event = new CustomEvent('seekrtech_finished_loading');
      window.dispatchEvent(event);
    };
    sendFinishedLoadingEvent();
  }, []);

  const navbarTitle = () => {
    if (!currentProjectLanguage) return '';
    const titleId = location.state?.titleId ?? null;
    if (titleId === null) {
      return isApp ? '' : localize(currentProjectLanguage, 'title_faq');
    }
    if (titleId === -2) {
      return localize(currentProjectLanguage, 'title_annoucement');
    }
    return allFoldersMap[titleId]?.name ?? '';
  };

  const navigate = () => {
    if (location.state && location.state.canNav) {
      history.goBack();
    } else {
      history.push(props.match.url + location.search);
    }
  };

  let isNavBtnVisible = false;
  if (canNav) {
    isNavBtnVisible = props.match.url !== props.location.pathname;
  } else {
    isNavBtnVisible =
      (location.state?.canNav && location.state?.isRoot === false) || false;
  }

  const handleContactUs = () => {
    window.dispatchEvent(new CustomEvent('seekrtech_contact_support'));
    if (!isApp) {
      setIsContactUsModalShown(true);
    }
  };

  const [pinnedArticles, setPinnedArticles] = useState([]);
  const [isLoadingPinnedArticles, setIsLoadingPinnedArticles] = useState(true);
  const [hotArticles, setHotArticles] = useState([]);
  const [isLoadingHotArticles, setIsLoadingHotArticles] = useState(true);
  useEffect(() => {
    if (!currentProjectLanguage) return;
    if (!currentProject) return;
    setIsLoadingPinnedArticles(true);
    axios
      .get('/articles', {
        params: {
          project: currentProject.id,
          project_language: currentProjectLanguage.id,
          pinned: true,
          region: region || undefined,
        },
      })
      .then((res) => {
        setPinnedArticles(res.data);
      })
      .finally(() => {
        setIsLoadingPinnedArticles(false);
      });
    setIsLoadingHotArticles(true);
    axios
      .get('/articles', {
        params: {
          project: currentProject.id,
          project_language: currentProjectLanguage.id,
          hot: true,
          region: region || undefined,
        },
      })
      .then((res) => {
        setHotArticles(res.data);
      })
      .finally(() => {
        setIsLoadingHotArticles(false);
      });
  }, [currentProject, currentProjectLanguage]);

  return isProjectLoaded || isLoadingPinnedArticles || isLoadingHotArticles ? (
    currentProject && currentProjectLanguage ? (
      <div className='Project'>
        {!isApp && (
          <Navbar
            title={navbarTitle()}
            navigate={navigate}
            color={currentProject.primary_color}
            showNavButton={isNavBtnVisible}
            showMoreButton={canNav}
            moreOnClick={() => {
              setIsNavbarPopupShown(true);
            }}
          />
        )}
        <NavbarPopup
          isShown={isNavbarPopupShown}
          setIsShown={setIsNavbarPopupShown}
          contactUsOnClick={handleContactUs}
        />
        <Switch>
          <Route exact path={props.match.url}>
            <ProjectIndex
              contactUsOnClick={handleContactUs}
              pinnedArticles={pinnedArticles}
              hotArticles={hotArticles}
            />
          </Route>
          <Route
            path={`/${currentProject.name}/folders/:folder/:folder_slug?`}
            component={FolderIndex}
          />
          <Route
            path={`/${currentProject.name}/articles/:article/:article_slug?`}
            component={Article}
          />
        </Switch>
        <ContactButton
          onClick={handleContactUs}
          primaryColor={currentProject.primary_color}
        />
        <IOSModal
          show={isContactUsModalShown}
          setIsShown={setIsContactUsModalShown}
          cancelText={localize(currentProjectLanguage, 'service_btn_cancel')}
          confirmText={localize(
            currentProjectLanguage,
            'service_btn_contactus',
          )}
          text={localize(currentProjectLanguage, 'service_alert')}
          color={currentProject.primary_color}
        />
      </div>
    ) : (
      <PageNotFound />
    )
  ) : (
    <Loading />
  );
}

Project.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default Project;
