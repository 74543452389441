import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearArticleLoaded,
  fetchCurrentArticle,
  setArticleLoaded,
} from '../../actions/article';
import not_available from '../../assets/not_available.svg';
import not_available_dark from '../../assets/not_available_dark.svg';
import { localize } from '../../helpers';
import { isDarkMode } from '../../params';
import BackButton from './BackButton';

function NotAvailable(props) {
  const dispatch = useDispatch();
  const { currentProjectLanguage, currentProjectLanguagesMap } = useSelector(
    (state) => ({
      ...state.projectReducer,
    }),
  );

  const article_no_translation = (languages) => {
    let match =
      localize(currentProjectLanguage, 'article_notranslation').match(
        /^(.+)<%>(.+)<%>(.+)<%>(.+)$/,
      ) || [];
    return (
      <div className='text'>
        {match[1]}
        <div>{currentProjectLanguage.language.name}</div>
        {match[2]}
        {languages
          .map((projectLanguageId) => (
            <div
              className={`link-text underline clickable${
                isDarkMode ? ' dark' : ''
              }`}
              key={projectLanguageId}
              onClick={() => {
                onClickLanguage(projectLanguageId);
              }}
            >
              {currentProjectLanguagesMap[projectLanguageId].language.name}
            </div>
          ))
          .reduce((prev, curr) => [prev, match[3], curr])}
        {match[4]}
      </div>
    );
  };

  const onClickLanguage = (projectLanguageId) => {
    dispatch(clearArticleLoaded());
    dispatch(fetchCurrentArticle(null, projectLanguageId)).then((res) => {
      dispatch(setArticleLoaded());
    });
  };

  return (
    <div className={`NotAvailable${isDarkMode ? ' dark' : ''}`}>
      <img
        src={isDarkMode ? not_available_dark : not_available}
        alt='not available'
      />
      {props.languages ? (
        article_no_translation(props.languages)
      ) : (
        <div className='text'>
          {localize(currentProjectLanguage, 'article_folder_notranslation')}
        </div>
      )}
      <BackButton history={props.history} />
    </div>
  );
}

NotAvailable.propTypes = {
  languages: PropTypes.array,
  history: PropTypes.object.isRequired,
};

NotAvailable.defaultProps = {
  languages: null,
};

export default NotAvailable;
