import clsx from 'clsx';
import React, { useState } from 'react';
import { animated, useTransition } from 'react-spring';
import '../../scss/PopupCell.css';

export default function PopupCell({ children, className, onClick }) {
  const [rippleIds, setRippleIds] = useState([]);
  const rippleTrans = useTransition(rippleIds, {
    from: { opacity: 1, transform: 'scale(2)' },
    enter: { opacity: 1, transform: 'scale(2)' },
    leave: { opacity: 0, transform: 'scale(10)' },
  });

  return (
    <div
      className={clsx('PopupCell', className)}
      onClick={onClick}
      onTouchStart={() => {
        const rippleId = new Date().getTime();
        setRippleIds((ids) => [...ids, rippleId]);

        setTimeout(() => {
          setRippleIds((ids) => ids.filter((id) => id !== rippleId));
        }, 0);
      }}
    >
      {children}
      {rippleTrans((style, item) => (
        <animated.div className='ripple' key={item} style={style} />
      ))}
    </div>
  );
}
