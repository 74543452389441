export const PLATFORMS = {
  ALL: 'platform_all',
  IOS: 'platform_ios',
  ANDROID_GOOGLE_PLAY: 'platform_android_googleplay',
  ANDROID_OTHERS: 'platform_android_others',
} as const;

export const Platform = {
  ALL: 'ALL',
  IOS: 'IOS',
  ANDROID_GOOGLE_PLAY: 'ANDROID_GOOGLE_PLAY',
  ANDROID_OTHERS: 'ANDROID_OTHERS',
} as const;

export const platformToL10nKey = {
  [Platform.ALL]: 'platform_all',
  [Platform.IOS]: 'platform_ios',
  [Platform.ANDROID_GOOGLE_PLAY]: 'platform_android_googleplay',
  [Platform.ANDROID_OTHERS]: 'platform_android_others',
} as const;
