import { combineReducers } from 'redux';

import projectReducer from './projectReducer';
import folderReducer from './folderReducer';
import articleReducer from './articleReducer';

export default combineReducers({
  projectReducer,
  folderReducer,
  articleReducer,
});
